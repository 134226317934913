<template>
	<v-container fluid>
		<v-form ref="formulario">
			<v-row>
				<v-col cols="12" class="d-flex flex-wrap pb-0" style="gap: 10px">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" color="primary" large @click.stop="crearColaborador">
								<v-icon color="white" left>mdi-content-save</v-icon>GUARDAR USUARIO
							</v-btn>
						</template>
						<span>Guardar el usuario</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								large
								text
								class="mx-1"
								:color="errors == null ? '' : errors ? 'error' : 'success'"
								@click="errors = !$refs.formulario.validate()"
							>
								<v-icon left v-if="errors === true">mdi-alert-circle-outline</v-icon>
								<v-icon left v-else-if="errors === false">mdi-check</v-icon>Consultar campos
							</v-btn>
						</template>
						<span v-if="!errors">Consultar campos faltantes o erroneos</span>
						<span v-else>Hay campos erróneos, revisa todos los campos</span>
					</v-tooltip>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Datos usuario" icon="account">
						<aux-input title="Correo">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.email"
								placeholder="Correo Usuario"
								:rules="[rules.req, rules.email]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Teléfono">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.telefono"
								placeholder="Teléfono Usuario"
								:rules="[rules.phone]"
							/>
						</aux-input>
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Permisos" icon="shield-lock-outline">
						<aux-input title="Usuario">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.usuario"
								placeholder="Login Usuario"
								:rules="[rules.req, rules.nombreRepetido]"
							/>
						</aux-input>
						<v-divider />
						<template v-if="!$route.params.id">
							<aux-input title="Contraseña">
								<v-text-field
									filled
									dense
									hide-details="auto"
									v-model="colaborador.password"
									placeholder="Contraseña"
									:rules="[rules.req]"
								>
									<template v-slot:append-outer>
										<v-btn class="mt-n2" color="primary" @click.stop="generarPassword">
											<v-icon>mdi-key</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</aux-input>
							<v-divider />
						</template>
						<v-list>
							<v-list-item-group v-model="colaborador.permisos" multiple>
								<v-list-item
									dense
									:value="Number(permiso.valor)"
									:key="permiso.valor"
									v-for="permiso in descPermisos"
								>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content>
											<v-list-item-title>{{ permiso.desc }}</v-list-item-title>
											<v-list-item-subtitle>{{ permiso.permiso }}</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</tarjeta-datos>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
import { req, ValidateSpanishID, phone, email, iban, isNumber } from '@/utils/validations.js';

export default {
	components: {
		TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
		AuxInput: () => import('@/components/AuxInput.vue'),
	},
	data() {
		return {
			errors: null,
			descPermisos: [],
			colaborador: { permisos: [] },
			colaboradores: [],
			loading: false,

			rules: {
				req, dni: ValidateSpanishID, phone, email, iban, isNumber,
				nombreRepetido: (v) => !this.colaboradores.find(x => x.usuario == v) || 'El nombre del usuario está repetido',
			},
		}
	},
	computed: {
		totalPermisos() {
			return this.colaborador.permisos.reduce((t, a) => t + a, 0)
		},
		colaboradoresFiltrados() {
			return this.colaboradores.filter((v, i, a) => a.findIndex(t => (t.IdAgente === v.IdAgente)) === i && v.ancestor_id == null && v.IdAgente != null && v.idColaborador != this.colaborador.idColaborador)
		}
	},
	methods: {
		getDescPermisos() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `/permisos`,
				}).then(({ data: permisos }) => {
					permisos.filter(p => this.$root.acceso(p.permiso)).forEach(({ permiso, valor, descripcion }) => this.descPermisos.push({ permiso, valor, desc : descripcion }))
					this.descPermisos = this.descPermisos.sort((a, b) => Number(a.valor) > Number(b.valor) ? 1 : -1)
					resolve()
				}).catch(err => {
					console.error(err)
					reject();
				})
			})
		},
		getColaboradores() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `/usuarios/`,
				}).then((res) => {
					this.colaboradores = res.data.filter(x => x.idUsuario != this.$route.params.id)
					resolve();
				}).catch(err => {
					console.error(err)
				})
			})
		}, generarPassword() {
			const Password = {
				_pattern: /[a-zA-Z0-9_\-\+]/,

				_getRandomByte: function () {
					// http://caniuse.com/#feat=getrandomvalues
					if (window.crypto && window.crypto.getRandomValues) {
						var result = new Uint8Array(1);
						window.crypto.getRandomValues(result);
						return result[0];
					}
					else if (window.msCrypto && window.msCrypto.getRandomValues) {
						var result = new Uint8Array(1);
						window.msCrypto.getRandomValues(result);
						return result[0];
					}
					else {
						return Math.floor(Math.random() * 256);
					}
				},

				generate: function (length) {
					return Array.apply(null, { 'length': length })
						.map(function () {
							var result;
							while (true) {
								result = String.fromCharCode(this._getRandomByte());
								if (this._pattern.test(result)) {
									return result;
								}
							}
						}, this)
						.join('');
				}


			};
			this.$set(this.colaborador, 'password', Password.generate(Math.round(Math.random() * (16 - 10 + 1) + 10)))
		},
		async getColaborador() {
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `/usuarios/${this.$route.params.id}`,
				}).then(({ data }) => {
					const potencias2 = new Array(50).fill(0).map((v, k) => 2 ** k);
					this.colaborador.permisos = potencias2.map(p => p & data.permisos).filter(p => p)

					this.colaborador = {
						...data,
						...this.colaborador,
					}

					resolve();
				}).catch(err => {
					console.error(err)
					reject()
				})
			});
		},
		async crearColaborador() {
			this.errors = false;
			if (!this.$refs.formulario.validate()) {
				this.errors = true;
				return;
			}

			axios({
				url: `/usuarios/nuevo`,
				method: 'POST',
				data: {
					usuario: {...this.colaborador, permisos: this.totalPermisos},
					idUsuario: this.$route.params.id
				},
			}).then(res => {
				this.$router.push({ name: 'Usuarios' })
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'Ha ocurrido un error inesperado')
			})
		}
	},
	async mounted() {
		this.loading = true;
		await Promise.all([
			(this.$route.params.id) ? this.getColaborador() : null,
			this.getDescPermisos(),
			this.getColaboradores(),
		])
		this.loading = false;
	},
}
</script>